import React from "react";
//import Partnerslidermultiple from "./Partnerslidermultiple";
import Video from "./Video";
import Testimonials from "./Testimonials";

export default function HomeContent() {

    return (
        <>
            <section style={{  background: "#56c4c8", padding: "1px" }} >
                    <h1 className="marquees" style={{ fontWeight: "700", color: "white" }}>SocIITy - Corporate and CSR Fundraising Entity of IIT Madras</h1>
            </section>
            <section class="funfactSection02">
                <div class="container largeContainer">
                    <div class="row">
                        <div class="col-lg-3 col-md-6 text-center">
                            <div class="funfact03 countfact" data-count="980">
                                <div class="factThumb"><img src="assets/images/png/partner.png" alt="" /></div>
                                <h2 style={{ fontSize: "60px", color: "var(--theme-color)" }}><span data-max="220"></span>+</h2>
                                <p className="achieve">CSR Partners</p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 text-center">
                            <div class="funfact03 countfact ffc02" data-count="450">
                                <div class="factThumb"><img src="assets/images/png/fund.png" alt="" /></div>
                                <h2 style={{ fontSize: "60px", color: "var(--theme-color)" }}><span data-max="400"></span>+</h2>
                                <p className="achieve">Cr. deployed in CSR funding</p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 text-center">
                            <div class="funfact03 countfact ffc04" data-count="30">
                                <div class="factThumb"><img src="assets/images/png/sdg.png" alt="" /></div>
                                <h2 style={{ fontSize: "60px", color: "var(--theme-color)" }}><span data-max="17"></span></h2>
                                <p className="achieve">UN SDGs Covered</p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 text-center">
                            <div class="funfact03 countfact ffc03" data-count="5822">
                                <div class="factThumb"><img src="assets/images/png/tax.png" alt="" /></div>
                                <h2 style={{ fontSize: "60px", color: "var(--theme-color)" }}><span data-max="100"></span>%</h2>
                                <p className="achieve">Tax exemption without limit</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <Partnerslidermultiple /> */}

            <section className="tabSection01">
                <h1 style={{ textAlign: "center", marginBottom: "15px", marginTop: "15px", color: "var(--theme-color)", paddingBottom: "5px", fontWeight: "800" }}>Popular CSR projects</h1>
                <div className="container largeContainer">
                    <div className="row">
                        <div className="col-lg-12">
                            <ul className="nav beproTab" id="beproTab" role="tablist">
                                <li role="presentation">
                                    <a className="active" id="financial-tab" data-toggle="tab" href="#financial" role="tab" aria-controls="financial" aria-selected="false">Education</a>
                                </li>
                                <li role="presentation">
                                    <a id="business-tab" data-toggle="tab" href="#business" role="tab" aria-controls="business" aria-selected="true">Water</a>
                                </li>
                                <li role="presentation">
                                    <a id="global-tab" data-toggle="tab" href="#global" role="tab" aria-controls="global" aria-selected="false">Healthcare</a>
                                </li>
                                <li role="presentation">
                                    <a id="agriculture-tab" data-toggle="tab" href="#agriculture" role="tab" aria-controls="agriculture" aria-selected="false">Agriculture</a>
                                </li>
                                <li role="presentation">
                                    <a id="sanitaion-tab" data-toggle="tab" href="#sanitaion" role="tab" aria-controls="sanitaion" aria-selected="false">Sanitation</a>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div className="tab-pane fade animated slideInUp2" id="business" role="tabpanel" aria-labelledby="business-tab">
                                    <div className="beproTabCon">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <p>
                                                    The International Centre for Clean Water (ICCW), established in 2018, is an initiative of IIT Madras. ICCW collaborates with research organizations, academic institutions, society, and government agencies to ensure clean and sustainable water for all.
                                                </p>
                                                <div style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                                                    <a style={{ alignItems: "center", justifyContent: "center" }} class="berpo_btn" href="#/water">
                                                        <span class="bp-shape"></span>
                                                        <span class="bp-shape"></span>
                                                        <span class="bp-shape"></span>
                                                        <span class="bp-shape"></span>
                                                        <span class="bp-text">Explore projects in water</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <img style={{ width: "100%" }} src="assets/images/home1/22.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade show active animated slideInUp2" id="financial" role="tabpanel" aria-labelledby="financial-tab">
                                    <div className="beproTabCon">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <p>
                                                    IIT Madras has a rich history of providing high-quality education to the masses through various initiatives such as NPTEL (National Programme on Technology Enhanced Learning). Continuing this tradition, IIT Madras introduced the unique BS (Bachelor of Science) in Data Science and Applications, which provides access to IIT-quality education to learners across India.
                                                </p>
                                                <div style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                                                    <a style={{ alignItems: "center", justifyContent: "center" }} class="berpo_btn" href="#/education">
                                                        <span class="bp-text">Explore projects in education</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <img style={{ width: "100%" }} src="assets/images/home1/NPTEL.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade animated slideInUp2" id="global" role="tabpanel" aria-labelledby="global-tab">
                                    <div className="beproTabCon">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <p>
                                                    Developing Innovative and affordable healthcare technology is the focus of various Centres of Excellence,
                                                    the Healthcare Technology Innovation Centre (HTIC) and several ongoing researches at IIT Madras. Healthcare Technology
                                                    Innovation Centre (HTIC) is a multi disciplinary R&D centre which is a joint initiative of IIT Madras and the Department
                                                    of Biotechnology, GoI.                                                    
                                                </p>
                                                <div style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                                                    <a style={{ alignItems: "center", justifyContent: "center" }} class="berpo_btn" href="#/health">
                                                        <span class="bp-shape"></span>
                                                        <span class="bp-shape"></span>
                                                        <span class="bp-shape"></span>
                                                        <span class="bp-shape"></span>
                                                        <span class="bp-text">Explore projects in healthcare</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <img style={{ width: "100%" }} src="assets/images/home1/33.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade animated slideInUp2" id="agriculture" role="tabpanel" aria-labelledby="agriculture-tab">
                                    <div className="beproTabCon">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <p>
                                                    We leverage our intellectual prowess to deliver technological solutions that cater to Agriculture and Sustainability. Pioneering innovative solutions to empower rural communities, IIT Madras research includes “Solar powered rural farms”, “Rapid Soil testing”, “Model villages with renewable energy and waste recycling”.
                                                </p>
                                                <div style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                                                    <a style={{ alignItems: "center", justifyContent: "center" }} class="berpo_btn" href="#/agriculture">
                                                        <span class="bp-shape"></span>
                                                        <span class="bp-shape"></span>
                                                        <span class="bp-shape"></span>
                                                        <span class="bp-shape"></span>
                                                        <span class="bp-text">Explore projects in agriculture</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <img style={{ width: "100%" }} src="assets/images/home1/11.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade animated slideInUp2" id="sanitaion" role="tabpanel" aria-labelledby="sanitaion-tab">
                                    <div className="beproTabCon">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <p>
                                                    IIT Madras is at the forefront of developing innovative and sustainable solutions for India’s sanitation challenges. Our faculty and students are engaged in various areas of research focused on the “Circular economy of Sanitation”,  “Advanced Waste-water Technologies” and “Farm-Energy Synergy” to enable safe, sustainable communities.
                                                </p>
                                                <div style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                                                    <a style={{ alignItems: "center", justifyContent: "center" }} class="berpo_btn" href="#/sanitation">
                                                        <span class="bp-shape"></span>
                                                        <span class="bp-shape"></span>
                                                        <span class="bp-shape"></span>
                                                        <span class="bp-shape"></span>
                                                        <span class="bp-text">Explore projects in sanitaion</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <img style={{ width: "100%" }} src="assets/images/home1/44.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Video />

            <section className="aboutSection01">
                <div className="container largeContainer">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="abThumb">
                                <img src="assets/images/home1/ashwin.jpg" alt="" />
                            </div>
                        </div>

                        <div className="col-lg-8">
                            <div className="abCon1">
                                <input type="checkbox" hidden className="read-more-state" id="read-more" />
                                <div className="read-more-wrap">
                                    <h2 className="secTitle" >Dean's Message</h2>
                                    <h5 style={{ textAlign: "center", fontFamily: 'fira sans,sans-serif' }}>Prof. Ashwin Mahalingam</h5>
                                    <h6 className="nameSec" style={{ textAlign: "center", fontFamily: 'fira sans,sans-serif', color: "var(--theme-color" }}>Dean, Alumni and Corporate Relations, IIT Madras</h6>
                                    <p>IIT Madras is poised to attain great heights. There are several interdisciplinary initiatives that IITM is undertaking in areas such as sustainability, healthcare and technology that have two things in common – they bring together the smartest minds in the country and solve some of the world's largest and most challenging problems. World class faculty who excel in research working in partnership with gifted students are making pathbreaking advances in these areas. <br/>IIT Madras is not only contributing to advances in scientific knowledge, we are also actively translating this research for practical impact.</p>
                                    <p className='read-more-target'> We are home to India’s most vibrant university-based startup ecosystem, advise several government agencies on matters of policy and work closely with companies and communities to implement projects that have a direct positive impact on people's lives. It is no wonder that we have consistently been ranked as the #1 University in the NIRF rankings.<br/>None of this would be possible without the generous support of our Alumni and the Corporates that we work with. Alumni, philanthropists and corporates have generously funded cutting edge research programs, world class infrastructure on our campus and have helped create an enabling environment for our students, staff and faculty to collaborate and produce significant outcomes. This support has accelerated our growth and enhanced our impact on society.<br/>IIT Madras now aspires to be one of the top universities in the world. To turn our dreams into reality, the support of our alumni, well-wishers and industry partners, both in terms of time and resources, is more crucial than ever. I hope that you will continue to be with us on this journey, and I look forward to meeting and working with each of you. I firmly believe that together, we will make the country, and the world, a better place to live in."</p>
                                </div>
                                <label for="read-more" class="read-more-trigger_closed" style={{ color: "#0f51eb", fontSize: "20px", fontFamily: 'fira sans,sans-serif' }}>Read More...</label>
                                <label for="read-more" class="read-more-trigger_opened" style={{ color: "#0f51eb", fontSize: "20px", fontFamily: 'fira sans,sans-serif' }}>Read Less</label>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{ marginTop: "50px", marginBottom: "30px" }}>
                        <div className="col-lg-8">
                            <div className="abCon1" >
                                <input type="checkbox" hidden className="read-more-state1" id="read-more1" />
                                <div className="read-more-wrap1">
                                    <h2 className="secTitle" >CEO's Message</h2>
                                    <h5 style={{ textAlign: "center", fontFamily: 'fira sans,sans-serif' }}>Kaviraj Nair</h5>
                                    <h6 className="nameSec" style={{ textAlign: "center" }}>CEO, Office of Institutional Advancement, IIT Madras.</h6>
                                    <p>IITM’s core focus area is science and technology, but this is never detached from social impact or relevance.
                                        All research, after all, has to benefit mankind. I am looking forward to building long-term relationships and meaningful engagement with patrons,
                                        existing and potential, in the corporate
                                        sector, and build intergenerational continuity in these relationships. Many of our alumni are
                                        stalwarts in their respective fields and I look forward to leveraging their expertise for the
                                        growth and benefit of IITM. I believe convergence, common interests and collaborative approach will
                                        be key to achieving better outcomes.</p>
                                    <p className='read-more-target1'>  At the ground level, I would like to augment the team to improve
                                        engagement at various levels, build on skills and work internally with all the stakeholders to help
                                        build proposals which can have transformative social impact at the national level. Certainly,
                                        strengthening the bridge between the industry and the academia will also remain a key focus area. </p>
                                </div>
                                <label for="read-more1" class="read-more-trigger_closed1" style={{ color: "#0f51eb", fontSize: "20px", fontFamily: 'fira sans,sans-serif' }}>Read More...</label>
                                <label for="read-more1" class="read-more-trigger_opened1" style={{ color: "#0f51eb", fontSize: "20px", fontFamily: 'fira sans,sans-serif' }}>Read Less</label>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="abThumb">
                                <img src="assets/images/team/kaviraj.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Testimonials />
        </>
    )
};