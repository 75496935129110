import React, { useState } from "react";
import "./VideoGalleryContent.css";

const videoData = [
  {
    id: 1,
    title: "CSR SUMMIT 2023",
    videoUrl: "assets/videogallery/AarthiHanumanthappa.mp4",
    thumbnail: "assets/images/csr2023/CSRHeads/2F9A9847.jpg",
  },
  {
    id: 2,
    title: "CSR SUMMIT 2023",
    videoUrl: "assets/videogallery/AmitKumar.mp4",
    thumbnail: "assets/images/csr2023/CSRHeads/2F9A9847.jpg",
  },
  {
    id: 3,
    title: "CSR SUMMIT 2023",
    videoUrl: "assets/videogallery/Andrew.mp4",
    thumbnail: "assets/images/csr2023/CSRHeads/2F9A9847.jpg",
  },
  {
    id: 4,
    title: "CSR SUMMIT 2023",
    videoUrl: "assets/videogallery/AnuragPratap.mp4",
    thumbnail: "assets/images/csr2023/CSRHeads/2F9A9847.jpg",
  },
  {
    id: 5,
    title: "CSR SUMMIT 2023",
    videoUrl: "assets/videogallery/BharathRamakrishnan.mp4",
    thumbnail: "assets/images/csr2023/CSRHeads/2F9A9847.jpg",
  },
  {
    id: 6,
    title: "CSR SUMMIT 2023",
    videoUrl: "assets/videogallery/DRMuralidharan.mp4",
    thumbnail: "assets/images/csr2023/CSRHeads/2F9A9847.jpg",
  },
  {
    id: 7,
    title: "CSR SUMMIT 2023",
    videoUrl: "assets/videogallery/DrPremNathSingh.mp4",
    thumbnail: "assets/images/csr2023/CSRHeads/2F9A9847.jpg",
  },
  {
    id: 8,
    title: "CSR SUMMIT 2023",
    videoUrl: "assets/videogallery/DrSreedathPanath.mp4",
    thumbnail: "assets/images/csr2023/CSRHeads/2F9A9847.jpg",
  },
  {
    id: 9,
    title: "CSR SUMMIT 2023",
    videoUrl: "assets/videogallery/DrTSSridhar.mp4",
    thumbnail: "assets/images/csr2023/CSRHeads/2F9A9847.jpg",
  },
  {
    id: 10,
    title: "CSR SUMMIT 2023",
    videoUrl: "assets/videogallery/GaneshBabu.mp4",
    thumbnail: "assets/images/csr2023/CSRHeads/2F9A9847.jpg",
  },
  {
    id: 11,
    title: "CSR SUMMIT 2023",
    videoUrl: "assets/videogallery/GayathriRamamoorthy.mp4",
    thumbnail: "assets/images/csr2023/CSRHeads/2F9A9847.jpg",
  },
  {
    id: 12,
    title: "CSR SUMMIT 2023",
    videoUrl: "assets/videogallery/KavithaSah.mp4",
    thumbnail: "assets/images/csr2023/CSRHeads/2F9A9847.jpg",
  },
  {
    id: 13,
    title: "CSR SUMMIT 2023",
    videoUrl: "assets/videogallery/MadhanRA.mp4",
    thumbnail: "assets/images/csr2023/CSRHeads/2F9A9847.jpg",
  },
  {
    id: 14,
    title: "CSR SUMMIT 2023",
    videoUrl: "assets/videogallery/MrSrikanth.mp4",
    thumbnail: "assets/images/csr2023/CSRHeads/2F9A9847.jpg",
  },
  {
    id: 15,
    title: "CSR SUMMIT 2023",
    videoUrl: "assets/videogallery/Narayan.mp4",
    thumbnail: "assets/images/csr2023/CSRHeads/2F9A9847.jpg",
  },
  {
    id: 16,
    title: "CSR SUMMIT 2023",
    videoUrl: "assets/videogallery/NeerajKapoor.mp4",
    thumbnail: "assets/images/csr2023/CSRHeads/2F9A9847.jpg",
  },
  {
    id: 17,
    title: "CSR SUMMIT 2023",
    videoUrl: "assets/videogallery/NeharikaRajagopalana.mp4",
    thumbnail: "assets/images/csr2023/CSRHeads/2F9A9847.jpg",
  },
  {
    id: 18,
    title: "CSR SUMMIT 2023",
    videoUrl: "assets/videogallery/Satish.mp4",
    thumbnail: "assets/images/csr2023/CSRHeads/2F9A9847.jpg",
  },
  {
    id: 19,
    title: "CSR SUMMIT 2023",
    videoUrl: "assets/videogallery/SmithaDGnanaolivu.mp4",
    thumbnail: "assets/images/csr2023/CSRHeads/2F9A9847.jpg",
  },
  {
    id: 20,
    title: "CSR SUMMIT 2023",
    videoUrl: "assets/videogallery/SubramanianChandramohan.mp4",
    thumbnail: "assets/images/csr2023/CSRHeads/2F9A9847.jpg",
  },
  {
    id: 21,
    title: "CSR SUMMIT 2023",
    videoUrl: "assets/videogallery/TamilselvanKaruvan.mp4",
    thumbnail: "assets/images/csr2023/CSRHeads/2F9A9847.jpg",
  },
  {
    id: 22,
    title: "CSR SUMMIT 2023",
    videoUrl: "assets/videogallery/UmmerKoyaVakkayil.mp4",
    thumbnail: "assets/images/csr2023/CSRHeads/2F9A9847.jpg",
  },

  // Add more video objects as needed
];

function VideoGalleryContent() {
  const [selectedVideo, setSelectedVideo] = useState(null);

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
  };

  const handleCloseModal = () => {
    setSelectedVideo(null);
  };
  return (
    <>
      <div className="video-gallery">
      <h1 className="video-gallery-heading">Video Gallery</h1>

      <div className="gallery">
        {videoData.map((video) => (
          <div
            key={video.id}
            className="video-item"
            onClick={() => handleVideoClick(video)}
          >
            {/* <img
              src={video.thumbnail}
              alt={video.title}
              className="thumbnail"
            /> */}
            <video width="600" controls >
              <source src={video.videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <p>{video.title}</p>
          </div>
        ))}
      </div>

      {selectedVideo && (
        <div className="modal" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={handleCloseModal}>
              &times;
            </button>
            <video width="600" controls autoPlay>
              <source src={selectedVideo.videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            {/* <h2>{selectedVideo.title}</h2> */}
          </div>
        </div>
      )}
    </div>
    </>
  );
}

export default VideoGalleryContent;
