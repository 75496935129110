import React from "react";
import "./SocialImpact.css";

function SocialImpactContent() {
  return (
    <div className="socialimpact">
      <h2 className="socialheading">The Social Impact Club</h2>
      <p className="iitm-initiative">An IIT Madras Initiative</p>
      <div className="container mb-2">
        <div className="row">
          <div className="col-12">
          <p>The Social Impact Club is an IIT Madras initiative aimed at creating a forum for CSR professionals where key focus areas and challenges faced by the CSR community are discussed, shared and collaboratively addressed. This Club, a congregation of impact-driven CSR leaders, offers a unique opportunity to share insights, exchange ideas with industry experts, and contribute to shaping the future of CSR in our country. </p>
      <p>
      The Social Impact Club also seeks to provide a platform for like-minded CSR leaders to connect, network, and build relationships that could lead to future collaborations and shared successes in the CSR domain. Click the below links to read the report.
      </p>
          </div>
        </div>
      </div>

      
      <div className="container">
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <a href="assets/images/socialimpact/The-Social-Impact-Club-Chennai-CSR-Cafe.pdf" className="gal-redirect" target="_blank">
              <div class="team_04 text-center">
                <div class="tmThumb">
                  <img src="assets/images/socialimpact/Chennai.png" alt="" />
                </div>
                <div class="down-report">
                  <h5 className="downloadreport">Download Report</h5>
                </div>
              </div>
            </a>
          </div>
          <div class="col-lg-3 col-md-6">
          <a href="assets/images/socialimpact/The-Social-Impact-Club-Mumbai-CSR-Cafe.pdf" className="gal-redirect" target="_blank">
            <div class="team_04 text-center">
              <div class="tmThumb">
                <img src="assets/images/socialimpact/Mumbai.png" alt="" />
              </div>
              <div class="down-report">
                  <h5 className="downloadreport">Download Report</h5>
                </div>
            </div>
            </a>
          </div>
          <div class="col-lg-3 col-md-6">
          <a href="assets/images/socialimpact/The-Social-Impact-Club-Delhi-CSR-Cafe.pdf" className="gal-redirect" target="_blank">
            <div class="team_04 text-center">
              <div class="tmThumb">
                <img src="assets/images/socialimpact/Delhi.png" alt="" />
              </div>
              <div class="down-report">
                  <h5 className="downloadreport">Download Report</h5>
                </div>
            </div>
            </a>
          </div>
          <div class="col-lg-3 col-md-6">
          <a href="assets/images/socialimpact/The-Social-Impact-Club-Bangalore-CSR-Cafe-Report.pdf" className="gal-redirect" target="_blank">
            <div class="team_04 text-center">
              <div class="tmThumb">
                <img src="assets/images/socialimpact/Bangalore.png" alt="" />
              </div>
              <div class="down-report">
                  <h5 className="downloadreport">Download Report</h5>
                </div>
            </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SocialImpactContent;
