import React from 'react'
import './CSRSubmit2024.css'
import Header from '../Component/Header';
import Footer from '../Component/Footer';

function CSRSubmit2024() {
  return (
    <>
    <Header />
            <section className="healthhead">
                <div className="container-fluid">
                    <h2 className='gallery-head' style={{ color: "black" }}>CSR SUMMIT 2024</h2>
                </div>
            </section>
            <section className='container largeContainer'>
                <div className="gallery-container1"> 
                <div>
                    <img src="assets/images/csr2024/1P5A8813.jpg" alt="csr2023-35" />
                </div>
                <div>
                    <img src="assets/images/csr2024/1P5A9596.jpg" alt="csr2023-36" />
                </div>

                <div>
                    <img src="assets/images/csr2024/1P5A9715.jpg" alt="csr2023-30" />
                </div>
                <div>
                    <img src="assets/images/csr2024/1P5A9749.jpg" alt="csr2023-31" />
                </div>
                <div>
                    <img src="assets/images/csr2024/2F9A0216.jpg" alt="csr2023-32" />
                </div>
                <div>
                    <img src="assets/images/csr2024/2F9A0250.jpg" alt="csr2023-33" />
                </div>
                <div>
                    <img src="assets/images/csr2024/2F9A0264.jpg" alt="csr2023-34" />
                </div>
                <div>
                    <img src="assets/images/csr2024/2F9A0282.jpg" alt="csr2023-35" />
                </div>
                <div>
                    <img src="assets/images/csr2024/2F9A0290.jpg" alt="csr2023-36" />
                </div>
                <div>
                    <img src="assets/images/csr2024/2F9A0501.jpg" alt="csr2023-36" />
                </div>
                <div>
                    <img src="assets/images/csr2024/2F9A9665.jpg" alt="csr2023-36" />
                </div>
                </div>
            </section>
            <Footer />
      
    </>
  )
}

export default CSRSubmit2024
