import React from "react";

function PartnersContent() {
    return (
        <>
            <section class="partnerh">
                <div class="container-fluid">
                    <h2 class="video-gallery-heading" style={{padding: "10px"}}>Our Partners</h2> 
                </div>
            </section>

            <section class="partner-page">
                <div class="container largeContainer">
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Aarvee-Associates.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Abi-Showatech-India-Ltd.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/accenture.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Adani-Enterprises.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Agilisium.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/AGS-Healthcare.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/ABB-India-Limited.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/ACSYS.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Akamai.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Alation-India.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/altair.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/amada.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/amadeus.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/americanexpress.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/anjan.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/ansys.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/arcelormittal.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/astrazeneca.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>




                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/AICL.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Alleima-India.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/AlphagrepSecurities.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Ambernath-Organics.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Anunta-Technologies.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/apaengineering.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>   
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/aptiv.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Arghyam-Foundation.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Asirvad.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/ATTLogo.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/avalon.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/atos.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                    <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/BakerHughes.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Banca-Sella.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Bill-Desk.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/BNY-Mellon.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Capgemini.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/cargill.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Bank-of-America.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/bharatpetroleum.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Bitzer.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/blue-star.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/BorgWarner.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Canara-Bank.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Castle-Ships.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Cholamandalam-MS-Risk.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Cintra.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/CME_Group.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/cams.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/concreteoem.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/cgi.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/cpcl.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/CHOLAFIN.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/cub.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Cochin-Shipyard.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/cognizant.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>


                <div class="row">
                    <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Cookson-India.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Crotus-Technologies.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/credavenue-yobi.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Daimler.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Dun-Bradstreet.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/ea-india.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>                        
                    </div>
                    <div class="row">
                    <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Encora.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/excelra.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/eaton.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/ecareindia.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Emerson.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/fedbank.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/fedex.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Ford_India.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/fortis.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/FOSROC.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Future_Generali_India.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Fluor-Daniel.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                    <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/gavs.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/GE-Power.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Geojit-Foundation.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/google.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/guavus.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/gaill.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                    <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/General-Insurance.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Goldman-Sachs.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Herbalife.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/hdfc.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Hermes.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/HTPF.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Hexaware.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/HFCL.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Hindustan-Colas.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Hindustan-Unilever.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/HSBC.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/huawei.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/cytiva.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/hyundai.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/ibm.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/icicihomefin.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/idbi.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/indegene.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                    <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/India-Nippon.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Indian-Oil.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/INDO-MIM.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Inductotherm.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/indus-bio.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Infineon.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/IndInfravitTrust.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/IndusTowers.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/IndusIndBank.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/inspirisys.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Intel-Technology.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/internationalseaport.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Ipsos.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/ittiam.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/janashikshasanstha.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/johnson-controls.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/KotakMahindraBank.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/LTIMindtree.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>

                    <div class="row">
                    <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/J-Mitra.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/John_Crane.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Karkinos.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/LandTTechnology.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/thales-landt.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/LegrandNovateur.png" alt=""/>
                                </div>                                
                            </div>
                        </div>                        
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/LatentView.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/LearningLinks.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/lgsoftindia.png" alt=""/>
                                </div>                                
                            </div>
                        </div>

                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/lkq-india.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Mazgon-Docks.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/mediadotnet.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Mercedes-Benz.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Milacron.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/mistral.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/mospi.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Mphasis.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/mrftyres.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                    <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Magna_International.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Mahindra.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/MIL.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/MSCSoftware-hexagon.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/nagarro.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/NATESAN.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/nbcc.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/nineleaps.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/nordex.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/ocpindia.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/oneplus.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/pelatro.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                    <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/NILE.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/nokia.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/nse.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/portescap.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/rkindustries.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Renault-Nissan.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                    <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/rmkv.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Robert-Bosch.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/REC.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Saipem.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/samsung.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/sar.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                    <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/sasken.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/sbi-foundation.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Shakti-Foundation.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/sharp.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Sidwal.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/solara-active.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                    <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Sterlite-Technologies.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/sundaram-finance.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Super-Auto-Forge.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Sutherland.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Syngene.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Syrma.png" alt=""/>
                                </div>                                
                            </div>
                        </div> 
                    </div>

                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Petronet-LNG-limited.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/PHN-Technologies.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Pioneer.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/powerfinance.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/powergrid.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Reddington.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/rr-donnelley.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Ruhrpumpen.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/sabre.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Sandvik-Alleima.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/SBI_General_Insurance.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/schaeffler.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/theshippingcorporation.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/siemens.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/snsfoundation.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Solverminds.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/SRLDiagnostics.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/SRL Limited-Fortis.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/StGobain.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Structural-Specialities.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/SVP-Philanthropy.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/TanlaFoundation.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/tata-advanced-systems.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/tataaialife.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/tcs.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Temenos.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/ThalesDisTechnology.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/The-Great-Eastern.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/thenatureconservancy.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/wellcometrust.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                    <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/tnpl.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Tata-Advanced-Materials.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Tata-Boeing.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Tata-Elxsi.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Tata-Lockheed-Martin.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Texas-Instrumnts.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                    <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Titan-Company.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Trimble-Information.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/TTK-Prestige.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Tube_Investments.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Turbo-Energy.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Verizon.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/TMB.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Tower_Research_Capital.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/TransunionGlobal.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Trident.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Ultratech-Cement.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/UniBio.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                    <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/VirtusaPolaris.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Walmart.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/WellsFargo.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Western_Digital.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Wood-India.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/ZOHO.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Venus-Safety-Health.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/vertiv.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Wabtec.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/wiprocares.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/WHO.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/yuken.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    {/* <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div> */}

				</div>
            </section>

        </>
    )
}

export default PartnersContent;